import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { AuthService } from "./services/auth.service";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { VerifyOtpComponent } from "./components/verify-otp/verify-otp.component";
import { FinalpreviewComponent } from "./pages/finalpreview/finalpreview.component";
import { KycComponent } from './kyc/kyc.component';
import { DocumentListComponent } from './components/document-upload/document-list/document-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
//import { DmiVideoConferenceComponent } from './components/dmi-video-conference/dmi-video-conference.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
//import { DmiMeetComponent } from './components/dmi-meet/dmi-meet.component';
import { NewDashboardComponent } from './pages/new-dashboard/new-dashboard.component';
import { LoginOtpComponent } from "./components/login-otp/login-otp.component";

const routes: Routes = [
  {
    path: "loan",
    loadChildren:
      () => import('./pages/baseapplication-new/baseapplication-new.module').then(m => m.BaseapplicationNewModule),
    canActivate: [AuthService]
  },
  {
    path: "sanction",
    loadChildren:
      () => import('./pages/loan-sanction/loan-sanction.module').then(m => m.LoanSanctionModule),
    canActivate: [AuthService]
  },
  {
    path: "collateral",
    loadChildren: () => import('./pages/technical/technical.module').then(m => m.TechnicalModule),
    canActivate: [AuthService]
  },
  {
    path: "report",
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthService]
  },
  {
    path: "disbursal",
    loadChildren: () => import('./pages/disbursal/disbursal.module').then(m => m.DisbursalModule),
    canActivate: [AuthService]
  },
  {
    path: "postdisbursal",
    loadChildren: () => import('./pages/post-disbursal/post-disbursal.module').then(m => m.PostDisbursalModule),
    canActivate: [AuthService]
  },
  {
    path: "mytask",
    loadChildren: () => import('./pages/mytask/mytask.module').then(m => m.MytaskModule),
    canActivate: [AuthService]
  },
  {
    path: "all-dashboard",
    loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.dashboards),
    canActivate: [AuthService]
  },
  { path: "login", component: LoginComponent  },
  // { path: "login", component: LoginComponent , canActivate:[LoginGuardService] },
  { path: "forgot_password", component: ForgetPasswordComponent },
  { path: "change_password", component: ChangePasswordComponent },
  { path: "verify_otp", component: VerifyOtpComponent },
  { path: "reset_password", component: ResetPasswordComponent },
  { path: "dashboard", component: NewDashboardComponent },
  { path: "login_otp", component: LoginOtpComponent },
  //{ path: "dmi-video-conference", component: DmiVideoConferenceComponent },
 // { path: "thank-you", component: ThankYouComponent },
  //{ path: "dmi-meet", component: DmiMeetComponent },



  {
    path: "master",
    loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule),
    canActivate: [AuthService]
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "final_preview", component: FinalpreviewComponent },
  {
    path: "kyc/start/:kycID",
    component: KycComponent
  },
  {
    path: "document/document-list/:leadID",
    component: DocumentListComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
