import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { UtilityService } from 'src/app/services/utility.service';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { Observable, Subscription } from 'rxjs';
import { DocumentUploadService } from 'src/app/services/document-upload.service';
import { DocumentUploadResService } from 'src/app/services/document-upload-res.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { map, catchError } from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentUploadComponent extends BaseComponent implements OnInit {
  @ViewChild('file') nameInputRef: ElementRef;
  //upload doucment thumnaill
  public imagePath;
  imgURL: any;
  public message: string;
  DocumentUpload_form: FormGroup;
  validationMessage = ValidationMessage;
  file: any;
  leadID: any;
  applicantID: any;
  leadApplicantNumber: any;
  DocumentByLeadAndTypeObj: any = [];
  selectedApplication: any;
  documentDropdownList: any;
  isFileUpload: boolean = false;
  uploadBtn: boolean = false;
  documentUploadData: any;
  subscription: Subscription;
  applicationLeadId: any;
  applicationTypeDetailId: any;
  docUploadResUrl: any = {};
  finalDocResUrl: any = [];
  docResId: any;
  docTypePdf: boolean = false;
  docTypeZip: boolean = false;
  docTypeExcel: boolean = false;
  docNameRequiredMsg: any;
  docTypeRequiredMsg: any;
  docFileRequiredMsg: any;
  documentHeader: any;
  dropdownSelectedValue: any;
  uploadErrMsg: string;
  checked = false;
  progress: number;
  progressStart: boolean = false;
  private url = environment.apiUrl;
  applicantList: any = [];
  docCodeErrMsg: any;
  documentPermissionData = {
    form: {
      isView: false,
      isEdit: false,
      isDelete: false
    },
    button: {
      isView: false,
      isEdit: false,
    }
  };
  uploadObj = {
    docUpload: [],
    docDelte: [],
    button: null
  };
  finalDelDocList = [];
  maxUploadResponse: any = [];
  maxFileUpLoadSize: any;
  showCheckBox: boolean = false;
  fileNameUpdated: string = '';
  mastersLists: any =[];
  fileTypeTxt:string='image/*,application/pdf,application/x-zip-compressed,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(public _fb: FormBuilder, public apiservice: ApiService, private sanitizer: DomSanitizer,
    private http: HttpClient, private utilityService: UtilityService,
    private documentUploadService: DocumentUploadService, private dialog: MatDialog,
    private docUploadResService: DocumentUploadResService,
  ) {
    super(utilityService);
    this.utilityService.globalApplicationSelected.subscribe(dataShared => this.selectedApplication = dataShared);
    this.subscription = this.documentUploadService.getDocumentUploadValue().subscribe(item => {

      this.documentPermissionData = {
        form: {
          isView: false,
          isEdit: false,
          isDelete: false
        },
        button: {
          isView: false,
          isEdit: false,
        }
      };
      this.uploadObj = {
        docUpload: [],
        docDelte: [],
        button: null
      };
      if (item) {
        this.fileTypeTxt='image/*,application/pdf,application/x-zip-compressed,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let headerArr=item.data.documentHeading.split('@');
        if(headerArr.length>=1){
          item.data.documentHeading=headerArr[0];
          if(headerArr[1] && headerArr[1]!=''){
            let fileTypeArr=headerArr[1].split('_');
            if(fileTypeArr && fileTypeArr.length){
              this.fileTypeTxt='';
              fileTypeArr.forEach(element => {
                let fileType=element.replace('_','').toLowerCase();
                switch(fileType){
                  case 'excel':{
                    this.fileTypeTxt+='.xls,.xlsx,';
                    break; 
                  }
                  case 'document':{
                    this.fileTypeTxt+='.doc,.docx,';
                    break; 
                  }
                  case 'pdf':{
                    this.fileTypeTxt+='.pdf,';
                    break; 
                  }
                  case 'zip':{
                    this.fileTypeTxt+='application/x-zip-compressed,';
                    break; 
                  }
                  case 'image':{
                    this.fileTypeTxt+='image/*,';
                    break; 
                  }
                }                
              });
              if(this.fileTypeTxt!=''){
                this.fileTypeTxt=this.fileTypeTxt.slice(0, -1);
              }              
            }
          }
        }
        
        this.docUploadResUrl = {};
        this.finalDocResUrl = [];
        this.finalDelDocList = [];
        this.documentUploadData = item;
        this.documentHeader = this.documentUploadData.data.documentHeading;
        this.dropdownSelectedValue = this.documentUploadData.data.dropdownSelectedValue;
        this.documentDropdownList = [];
        this.DocumentByLeadAndTypeObj = [];
        this.uploadObj.button = item.data.button;
        this.getDocumentByLeadAndType();
        let docPermissionData = item.component.docPermissionData;
        let buttonName: string = item.data.button;
        if (buttonName != undefined && docPermissionData.hasOwnProperty(buttonName)) {
          if (docPermissionData[buttonName].elementType == 'form') {
            this.documentPermissionData.form.isView = docPermissionData[buttonName].form.isView;
            this.documentPermissionData.form.isEdit = docPermissionData[buttonName].form.isEdit;
            this.documentPermissionData.form.isDelete = docPermissionData[buttonName].form.isDelete;
            this.documentPermissionData.button.isView = docPermissionData[buttonName].form.componentPrivileges[buttonName].isView;
            this.documentPermissionData.button.isEdit = docPermissionData[buttonName].form.componentPrivileges[buttonName].isEdit;
          } else {
            this.documentPermissionData.form.isView = docPermissionData[buttonName].form.isView;
            this.documentPermissionData.form.isEdit = false;
            this.documentPermissionData.form.isDelete = false;
            this.documentPermissionData.button.isView = docPermissionData[buttonName].form.componentPrivileges[buttonName].isView;
            this.documentPermissionData.button.isEdit = false;
          }
        }
        // documentHeader == 'CPV Firing' || documentHeader == 'Legal Firing' || documentHeader == 'KYC' || documentHeader == 'CPV Report' || documentHeader == 'Technical Firing' || documentHeader == 'Technical Report' || documentHeader == 'Legal Report' || documentHeader == 'Request Document' || documentHeader == 'OCR Bank Proof' || documentHeader == 'ACH' || documentHeader == 'Bank Proof' || documentHeader == 'Request Form' || documentHeader == 'Deposite Slip'
        if (item.data.hideCheckBox == 0) {
          this.showCheckBox = true;
        } else {
          this.showCheckBox = false;
        }
      } else {
        this.documentUploadData = [];
      }
    });
  }
  public ngAfterViewChecked(): void {
  }
  ngOnInit() {
    this.DocumentUpload_form = this._fb.group({
      documentType: [''],
      documentName: [''],
      document: [''],
    })
  }
  mimeType: any;
  preview(fileInputEvent) {
    this.isFileUpload = true;
    this.file = fileInputEvent[0];
    this.fileNameUpdated = fileInputEvent[0].name.replace(/[^a-zA-Z0-9-.]/g, "");
    this.fileNameUpdated = this.fileNameUpdated.replace(/\.(?=.*\.)/g, "");

    if (fileInputEvent.length === 0) return;
    this.mimeType = fileInputEvent[0].type;
    if (this.mimeType == 'application/pdf') {
      this.docTypePdf = true;
      this.docTypeZip = false;
      this.docTypeExcel = false;

    } else if(this.mimeType == 'application/x-zip-compressed'){
      this.docTypeZip = true;
      this.docTypePdf = false;
      this.docTypeExcel = false;
    }else if(this.mimeType == 'application/vnd.ms-excel' ||  this.mimeType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.docTypeZip = false
      this.docTypePdf = false;
      this.docTypeExcel = true;
    } else {
      this.docTypeZip = false
      this.docTypePdf = false;
      this.docTypeExcel = false;
    }

    if (this.mimeType == 'application/pdf' || this.mimeType == 'image/jpeg' || this.mimeType == 'image/png' || this.mimeType == 'image/jpg' || this.mimeType == 'application/x-zip-compressed' || this.mimeType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.mimeType=='application/vnd.ms-excel') {
      this.uploadErrMsg = '';
    } else {
      this.uploadErrMsg = this.validationMessage.documentUpload.supportedDocumentErrMsg;
    }
    var reader = new FileReader();
    this.imagePath = fileInputEvent;
    reader.readAsDataURL(fileInputEvent[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
      if (this.imgURL !== '') {
        this.docFileRequiredMsg = '';
      }
    };
    this.getMaxUpLoadSize();
  }
  resetInputValue() {
    this.docNameRequiredMsg = ''
  }
  docTypeValue() {
    this.docTypeRequiredMsg = '';
    this.documentNameUpdate();
  }
  getMaxUpLoadSize() {
    this.apiservice.get(APIConstant.MAXUPLOADSIZE, {}, resObj => {
      this.maxUploadResponse = resObj;
      this.maxFileUpLoadSize = this.maxUploadResponse[0].configValue;
    })
  }
  uploadDocument() {
    this.leadApplicantNumber = this.documentUploadData.data.leadApplicantNumber;
    let documentName = this.DocumentUpload_form.controls.documentName.value;
    let documentID = this.DocumentUpload_form.controls.documentType.value;
    if (documentName == '' || documentName === undefined) {
      this.docNameRequiredMsg = this.validationMessage.documentUpload.documentNameErr;
      return;
    }
    if (documentID == '' || documentID === undefined) {
      this.docTypeRequiredMsg = this.validationMessage.documentUpload.documentTypeErr;
      return;
    }
    let rowIdentifier = '';
    if (this.documentUploadData.data.rowIdentifier != null && this.documentUploadData.data.rowIdentifier != '' && this.documentUploadData.data.rowIdentifier != undefined) {
      rowIdentifier = this.documentUploadData.data.rowIdentifier;
    }
    if (this.file) {
      this.DocumentUpload_form.get('document').setValue(this.file);
    }
    const formData = new FormData();
    let documentFile = this.DocumentUpload_form.get('document').value;
    if (documentFile == '' || this.imgURL == '') {
      this.docFileRequiredMsg = this.validationMessage.documentUpload.documentUploadErr;
      return;
    }
    this.uploadBtn = true;
    if (this.documentUploadData.data.leadID) {
      this.leadID = this.documentUploadData.data.leadID;
      formData.append('leadID', this.leadID);
    }
    if (this.documentUploadData.data.uploadCode) {
      formData.append('refRecordCode', this.documentUploadData.data.uploadCode);
    }
    if(this.documentUploadData.data.bankNameTypeDetailID){
    this.utilityService.isSidebarOpen = false;
    formData.append('bankNameTypeDetailID',this.documentUploadData.data.bankNameTypeDetailID)
    }
    formData.append('documentName', documentName);
    formData.append('documentID', documentID);
    formData.append('leadApplicantNumber', this.leadApplicantNumber);
    formData.append('document', documentFile, this.fileNameUpdated);
    formData.append('rowIdentifier', rowIdentifier);
    if (this.documentHeader == "CPV Firing" && this.file.size / (1024 * 1024) > this.maxFileUpLoadSize) {
      this.utilityService.notify(this.validationMessage.toasterClass.error, `File size is not allowed more than ${this.maxFileUpLoadSize}MB`);
      this.resetDocForm();
      return;
    }
    if((this.documentHeader=="Stamp" || this.documentHeader=="Agency") && !(this.mimeType == 'application/pdf')){
      alert('Only Pdf allowed');
      this.resetDocForm();
      return;
    }
    if(this.documentHeader == 'Technical Property Images' && !(this.mimeType == 'image/jpeg' || this.mimeType == 'image/png' || this.mimeType == 'image/jpg')){
      alert('Only JPEG/PNG/JPG allowed');
      this.resetDocForm();
      return;
    }

    let apiURL = `${this.url}` + 'v1/document/upload/';
    if (this.documentUploadData.data.uploadType == 'other') {
      apiURL = `${this.url}` + 'v2/document/upload/';
    }
    this.http.post(apiURL, formData, {
      reportProgress: true,
      observe: "events"
    })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.docUploadResUrl = {
              documentName: event.body.responseObj.documentName,
              uploadedDocumentPath: event.body.responseObj.uploadedDocumentPath,
              applicationDocumentID: event.body.responseObj.applicationDocumentID,
            }
            this.finalDocResUrl.push(this.docUploadResUrl);
            this.uploadObj.docUpload = this.finalDocResUrl;
            this.documentUploadData.component.FileUploadCallback(this.uploadObj);
            this.resetDocForm();
            this.utilityService.notify(this.validationMessage.toasterClass.success, event.body.responseMsg);
            this.getDocumentByLeadAndType();
          }
        }),catchError(error => this.handleError(error))
      ).toPromise();
    return;
    // this.apiservice.multipartFormPost(APIConstant.UPLOAD_DOCUMENT, formData, resObj => {
    //   // this.docResId = resObj.responseObj.applicationDocumentID;
    //   this.docUploadResUrl = {
    //     documentName: resObj.responseObj.documentName,
    //     uploadedDocumentPath: resObj.responseObj.uploadedDocumentPath,
    //     applicationDocumentID: resObj.responseObj.applicationDocumentID,
    //   }
    //   this.finalDocResUrl.push(this.docUploadResUrl);
    //   if (this.documentUploadData && this.documentUploadData.callback) {
    //     this.documentUploadData.component.FileUploadCallback(this.finalDocResUrl);
    //   }
    //   this.DocumentUpload_form.controls['documentType'].setValue('');
    //   this.DocumentUpload_form.controls['documentName'].setValue('');
    //   this.docNameRequiredMsg = '';
    //   this.docTypeRequiredMsg = '';
    //   this.docFileRequiredMsg = '';
    //   this.imgURL = '';
    //   this.isFileUpload = false;
    //   this.uploadBtn = false;
    //   this.utilityService.notify(this.validationMessage.toasterClass.success, resObj.responseMsg);
    //   this.getDocumentByLeadAndType();
    // });
  }
  private handleError(error:any):Observable<never>{
    this.utilityService.notify(this.validationMessage.toasterClass.error,error.error.responseMsg);
    this.resetDocForm();
    return new Observable<never>(() => {});
    // throw new Error(error);
  }



  getDocumentByLeadAndType() {
    // console.log('config value',this.utilityService.getConfigValue())
    let paramsData ;
    if(this.documentUploadData.data.documentHeading=="PD FI Report"
      || this.documentUploadData.data.documentHeading=="PD FI Firing")
    {
      paramsData = {
        codeIDList: this.getDocumentCodeTypeDetailIDs(this.utilityService.codeIDList),
        documentSubTypeList: this.utilityService.documentSubTypeList,
        applicantNumber: this.leadApplicantNumber
      }
    }
    else{
      paramsData = {
        codeID: this.documentUploadData.data.documentCode
      }
    }
    let apiURL = APIConstant.DOCUMENT_AND_TYPE_LIST;
    if (this.documentUploadData.data.uploadType && this.documentUploadData.data.uploadType == 'other') {
      apiURL = 'v2/document/';
    }
    if (this.documentUploadData.data.uploadCode != null && this.documentUploadData.data.uploadCode != '' && this.documentUploadData.data.uploadCode != undefined) {
      paramsData['refRecordCode'] = this.documentUploadData.data.uploadCode;
    }
    if (this.documentUploadData.data.leadID != null && this.documentUploadData.data.leadID != '' && this.documentUploadData.data.leadID != undefined) {
      paramsData['lead'] = this.documentUploadData.data.leadID;
    }
    if (this.documentUploadData.data.leadApplicantNumber != null && this.documentUploadData.data.leadApplicantNumber != '' && this.documentUploadData.data.leadApplicantNumber != undefined) {
      paramsData['applicantNumber'] = this.documentUploadData.data.leadApplicantNumber;
    }
    if (this.documentUploadData.data.rowIdentifier != null && this.documentUploadData.data.rowIdentifier != '' && this.documentUploadData.data.rowIdentifier != undefined) {
      paramsData['rowIdentifier'] = this.documentUploadData.data.rowIdentifier;
    }
    // console.log(this.documentUploadData.data.documentIds)
    if (this.documentUploadData.data.documentIds == 'tempCall') {
      // To prevent http calls and temporary close the modal  for ROI waiver upload screen.
    }
    else if (this.documentUploadData.data.documentIds || this.documentUploadData.data.documentIds == 0) {
      // console.log(this.documentUploadData.data.documentCode)
      this.apiservice.get(apiURL, { codeID: this.documentUploadData.data.documentCode }, resObj => {
        if (this.documentUploadData.data.uploadType != 'lead' && this.documentUploadData.data.dropdownSelectedValue != '' && this.documentUploadData.data.dropdownSelectedValue != undefined) {
          let sel = this.documentUploadData.data.dropdownSelectedValue;

          let docTypeArr = resObj.documentTypes.filter(function (e) {
            return e.documentSubTypeDetail == sel;
          })
          this.documentDropdownList = docTypeArr;
        } else {
          this.documentDropdownList = resObj.documentTypes;
        }
        this.resetDocForm();
      }, err => {
        this.resetDocForm();
      })
      this.apiservice.get(apiURL + this.documentUploadData.data.documentIds, paramsData, resObj => {
        this.DocumentByLeadAndTypeObj = resObj;
        this.resetDocForm();
      }, err => {
        this.resetDocForm();
      })
    }
    else {
      this.apiservice.get(apiURL, paramsData, resObj => {
        if (this.documentUploadData.data.uploadType != 'lead' && this.documentUploadData.data.dropdownSelectedValue != '' && this.documentUploadData.data.dropdownSelectedValue != undefined) {
          let sel = this.documentUploadData.data.dropdownSelectedValue;

          let docTypeArr = resObj.documentTypes.filter(function (e) {
            return e.documentSubTypeDetail == sel;
          })
          this.documentDropdownList = docTypeArr;
        } else {
          this.documentDropdownList = resObj.documentTypes;
        }
        // this.documentDropdownList = resObj.documentTypes;
        this.DocumentByLeadAndTypeObj = resObj.documents;
        let newArray = [];
        if(this.documentUploadData.data.documentHeading == 'Service Response' || this.documentUploadData.data.documentHeading == 'Service Request'){
          this.DocumentByLeadAndTypeObj.forEach(element => {
            if(element.documentSubTypeDetail == this.documentUploadData.data.dropdownSelectedValue){
              newArray.push(element)
            }
          });
          this.DocumentByLeadAndTypeObj = newArray;
        }
        this.resetDocForm();
        if(this.documentDropdownList &&
          this.documentDropdownList.length &&
          this.documentUploadData.data.uploadType == 'lead' &&
          this.documentHeader.toLowerCase() != 'kyc' &&
          this.documentUploadData.data.dropdownSelectedValue != '' &&
          this.documentUploadData.data.dropdownSelectedValue != undefined &&
          this.documentPermissionData.form.isEdit
          ){
            let docCode=this.documentUploadData.data.dropdownSelectedValue.toLowerCase();
            let docListSelcArr=this.documentDropdownList.filter(function(e){
              return e.documentCodeTypeDetail.toLowerCase()==docCode;
            })
            console.log(docListSelcArr);
            if(docListSelcArr.length){
              this.DocumentUpload_form.get('documentType').patchValue(docListSelcArr[0].documentID);
              this.documentNameUpdate();
            }
        }
      }, err => {
        this.resetDocForm();
      })
    }

  }
  downloadDocument(document) {
    this.apiservice.downloadFile(document.applicationDocumentID);
  }
  deleteDocument(document) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      data: {
        message: this.validationMessage.common.deleteCOnfirmation,
        buttonText: {
          ok: 'Ok',
          cancel: 'Cancel'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.DocumentByLeadAndTypeObj.splice(this.DocumentByLeadAndTypeObj.indexOf(document), 1);
        let applicationDocumentID = document.applicationDocumentID;

        this.finalDelDocList.push({ id: applicationDocumentID });
        this.uploadObj.docDelte = this.finalDelDocList;
        this.documentUploadData.component.FileUploadCallback(this.uploadObj);

        this.apiservice.delete(APIConstant.DELDOCUMENT + applicationDocumentID, {}, resObj => {
        }, err => {
          Swal.fire({
            allowOutsideClick: false,
            text: 'just text',
            html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + err.responseMsg + '</p>',
          })
        });
      }
    });
  }
  checkedForSelectDocument(currentDoc, thsobj, index) {
    if (!thsobj.checked) {
      let filteredItem = this.finalDocResUrl.filter(item => {
        return item.applicationDocumentID === currentDoc.applicationDocumentID
      })
      this.finalDocResUrl.splice(filteredItem, 1);
      this.uploadObj.docUpload = this.finalDocResUrl;
      this.documentUploadData.component.FileUploadCallback(this.uploadObj);
    } else {
      this.docUploadResUrl = {
        documentName: currentDoc.documentName,
        uploadedDocumentPath: currentDoc.uploadedDocumentPath,
        applicationDocumentID: currentDoc.applicationDocumentID,
      }
      this.finalDocResUrl.push(this.docUploadResUrl);
      this.uploadObj.docUpload = this.finalDocResUrl;
      this.documentUploadData.component.FileUploadCallback(this.uploadObj);

    }

  }
  documentNameUpdate() {
    let docName = '';
    let docID = this.DocumentUpload_form.get('documentType').value;
    if (docID != '' && docID != null && docID != undefined && docID > 0) {
      let docTypes = this.documentDropdownList.filter(item => {
        return docID == item.documentID;
      });
      if (docTypes.length) {

        docName += ' ' + docTypes[0].documentSubTypeDetailDisplayText;
        if (this.documentUploadData.data.leadApplicantNumber != '' && this.documentUploadData.data.leadApplicantNumber != null && this.documentUploadData.data.leadApplicantNumber != undefined) {
          docName += ' ' + this.documentUploadData.data.leadApplicantNumber;
        }
        if (this.DocumentByLeadAndTypeObj && this.DocumentByLeadAndTypeObj.length) {
          let savedDocCount = this.DocumentByLeadAndTypeObj.filter(item => {
            return item.documentSubTypeDetail.toUpperCase() == docTypes[0].documentSubTypeDetail.toUpperCase();
          });
          if (savedDocCount.length) {
            docName += ' ' + savedDocCount.length;
          }
        }
      }
    }
    if (this.documentHeader.toLowerCase() == 'service request') {
      docName = 'Request' + docName;
    }
    if (this.documentHeader.toLowerCase() == 'service response') {
      docName = 'Response' + docName;
    }

    if (this.documentHeader.toLowerCase() == 're-initiate') {
      docName = 'Re-' + docName;
    }
    this.DocumentUpload_form.get('documentName').setValue(docName);
    this.resetInputValue();
  }
  resetDocForm() {
    this.DocumentUpload_form.controls['documentType'].setValue('');
    this.DocumentUpload_form.controls['documentName'].setValue('');
    if (this.nameInputRef) {
      this.nameInputRef.nativeElement.value = null;
    }
    this.docNameRequiredMsg = '';
    this.docTypeRequiredMsg = '';
    this.docFileRequiredMsg = '';
    this.imgURL = '';
    this.isFileUpload = false;
    this.uploadBtn = false;
    this.progress = null;
    this.fileNameUpdated = '';
    this.file = null;
  }
  close(){
    this.utilityService.isSidebarOpen = false;
  }



  getDocumentCodeTypeDetailIDs(arrays: any[] ) {
    console.log(this.masterTypeDetailId('DocumentCode',arrays),arrays)
    let ids;
    arrays.forEach(array => {
        if (ids) {
          ids = ids + ','+ this.masterTypeDetailId('DocumentCode',array);
        }else{
          ids = this.masterTypeDetailId('DocumentCode',array);
        }
    });
    return ids;
  }


}
