export const APIConstant = {
  // Post Common URL

  // Get Common URL
  MASTERS: 'v1/master',
  LOANPRODUCT: 'v1/master/loan-product-purpose',
  ALLMASTERS: 'v1/master/all/',
  STATES: 'v1/master/states/',
  DISTRICT: 'v1/master/state/',
  GETDISTRICT: 'v1/master/state/',
  CITY: 'v1/master/district/',
  LOANAPPLICATIONLIST: 'v1/loan/application/',
  SEARCH_LOAN_APPLICATION: 'v1/loan/application/search',
  NATURETRANSCATEGORY: 'v1/master/prop-nature/',
  PINCODE: "v1/pincode/",
  BRANCHLIST: "v1/master/branch",
  REGIONLIST: "v1/master/region",
  APPLICANTCOUNT: "v1/loan/applicant/",  // get all applicant details of lead
  MAXUPLOADSIZE: "v1/support/task/utility/appConfig",
  APPVERSTION:'v1/master/auth/app-version/',
  EXPENSES:'v1/master/expense',
  GET_FI_DATA: 'v1/loan/sanction/agencyfi/verification/{fiType}/{refRecordCode}',
  GET_ALL_DEDUPES: 'v1/loan/de-dupe/for/{dedupeForTypeDetailID}',
  UPDATE_GAURANTOR_DEDUPE: 'v1/loan/de-dupe/remarks/{deDupeID}',
  UPDATE_FINAL_STATUS: 'v1/loan/de-dupe/remarks',
  // GET_FI_DATA_NEW: 'v1/loan/sanction/agencyfi/verification/V1/{fiType}/{refRecordCode}',
  GET_FI_DATA_NEW: 'v1/loan/sanction/agencyfi/verification/{fiType}/loan-application/{loanApplicationID}',
  EMANDATE:'v1/loan/nupay/create-emandate/loan/{loanApplicationID}/ach/{achId}',
  ACH_STATUS:'v1/loan/nupay/status/loan/{loanApplicationID}/ach/{achId}',
  REJECT_PUT:'v1/loan/application/ach/status',
  ZONELIST:'v1/master/zones',
  ZONE_WISE_REGIONLIST: "v1/master/zones/{zoneID}/regions",
  REGION_WISE_BRANCHLIST: "v1/master/regions/{regionID}/branches",
  // Delete Common URL

  // login Authentication
  LOGIN_API: 'v1/auth/',
  FORGOTPASSWORD: 'v1/paasword/forgot/password',
  CHANGEPASSWORD:'v1/changePassword/submit/',

  // My Task
  APPQUEUE: 'v1/task/{showTeamQueue}/{isCompleted}/',
  ASSIGNGET: 'v1/master/getQueName/lead/{leadID}/queueName/{queueName}',
  ASSIGNPOST: 'v1/task/',
  GET_QUEUE_NAME_BY_LEADID: 'v1/master/getQueName',
  REOPEN_APPLICATION:'v1/master/re-open/',
  APPQUEUE_COUNT: 'v1/task/count/{showTeamQueue}/{isCompleted}/',
  APPQUEUE_ASSIGNMENT: 'v1/task/{showTeamQueue}/{isCompleted}/{queueID}/',

  // Loan Downsize
  GET_LOANDOWNSIZE_DETAILS: 'v1/loan/downsize/get/{leadID}',
  POST_LOANDOWNSIZE_DETAILS: 'v1/loan/downsize/process',

  // Loan Information URL
  TVRCHECKLIST: 'v1/tvr/check/list/',
  TVRCHECKLISTPOST: 'v1/tvr/check/list/',
  PROCESSINGFEE: 'v1/loan/fee/lead/{leadID}/product/{productID}/purpose/{loanPurposeID}',
  LOANINFO: 'v1/loan/applicant/loan/information/',
  GETLEADINFO: 'v1/lead/',
  GETLOAN_INFO: 'v1/loan/applicant/loan/information/',
  GETAPPLICANT_DATA: 'v1/loan/applicant/{leadID}',
  // Personal
  PERSONALDETAILS: 'v1/loan/application/applicant/personal/detail/',
  GENERATEOTP: 'v1/common/contact/verification/mobile/get/otp',
  VERIFYOTP: 'v1/common/contact/verification/mobile/verify/otp',
  GETPERSONAL_DET: 'v1/loan/application/applicant/personal/detail/',
  DELETEKYC: 'v1/loan/application/applicant/personal/detail/kyc/',
  PERFORMKYCPOST: 'v1/kyc/user/data/',
  GETPERFORMKYCDATA: 'v1/auth/kyc/type/user/data?kycID=',
  KYCREQUESTDATA: 'v1/auth/kyc/request/data',
  OFFICERLIST: 'v1/employee',
  FETCHKYC:'v1/kyc/getKycDetails/',
  // De-Dupe Verification
  DEDUPELIST: 'v1/loan/applicant/dedupe/getAll/',
  DEDUPSUBMIT: 'v1/loan/applicant/dedupe/updateRemarks',
  // Employment
  GETDEFAULTCOMPANY:'v1/loan/application/employment/defaulter-company/request/lead/{leadID}',
  POSTDEFAULTCOMPANY:'v1/loan/application/employment/defaulter-company/request',

  // Income
  INCOMEHEADER: "v1/loan/applicant/income/header/",

  // Bank Details
  BANKACNTAGGREGATOR: "v1/account/aggregator/lead/{leadID}/applicant/{applicantNumber}",
  COPYBANKDETAILS: "v1/account/aggregator/banking-model/copy-txn/{accAgrBankingModelID}",
  ACCAGGREGATORV2 : "v1/finvu/initiate-consent-request/{leadID}/{applicantNumber}",

  // hunter
  GETTRIGGERHUNTER: 'v1/loan/hunter/triggerHunterAPI/',
  GETHUNTERALL: 'v1/loan/hunter/getAll/',
  POSTHUNTER: 'v1/loan/hunter/hunterStatus/',

  // Liablity & assets

  // Bureau
  GETBUREAUDATA: 'v1/bureau/{leadID}',
  GENERATE_BUREAU_REPORT: 'v1/loan/sanction/external/triggerCibil/',
  GENERATE_EXPERIAN_BUREAU_REPORT: 'v1/loan/sanction/external/triggerExperian/',
  GENERATE_HIGHMARK_BUREAU_REPORT: 'v1/crif/high/mark/Inquiry',
  GENERATE_ASCORE_REPORT: 'v1/loan/model/ascore/lead/{leadID}/applicant/{applicantID}',
  // GENERATE_INCOME_REPORT: 'v1/loan/applicant/income/model/',
  GET_IDV_RESPONSE: 'v1/lead/idv/{leadID}',
  INCOME_DATA_VIEW: 'v1/loan/applicant/income/model-v3/',

  // PD Report
  PDREPORT: 'v1/loan/sanction/pdreport/',
  GET_PDFILIST: 'v1/loan/sanction/agencyfi/verification/PD/loan-application/{loanApplicationID}',
  POST_PDFI:'v1/loan/sanction/agencyfi/verification/process',
  GET_INTERNAL_USERPD:'v1/master/getQueName/',
  GET_EXTERNAL_AGENCYPD:'v1/master/agency/AgencyType/PDAgency/{branchID}/AgencyModuleType/PDFi',
  PD_CONFIGDOC:'v1/master/configvalue/{PDDocumentType}',

  // pd assigned

  PD_ASSIGNED:'v1/meeting/schedule/all',
  PUT_STATUS : 'v1/meeting/schedule',


  // POSTPDREPORT:'/v1/loan/sanction/pdreport/',
  GETPORTFOLIOPARTNERS: 'v1/portfoliopartners/get/{engagementType}',
  GETAPPLICANTRISKDATE: 'v1/applicant-risk-evaluation/get/',
  // Reference

  // Disbursal
  GETPROPERTYVISIT: 'v1/loan/property/visit/',
  ADDPROPERTYVISIT: 'v1/loan/property/visit/',
  PROPVISITREOPEN: 'v1/loan/property/property-visit/re-open',

  //Ach Pdc Security
  ACH_PDC_SECURITY_Summary: 'v1/loan/disburse/cheque/summary/',
  GET_ACH_DETAILS: 'v1/loan/application/ach/',
  APPLICANT_COUNT: 'v1/loan/applicant/',
  POST_ACH_DETAILS: 'v1/loan/application/ach/addLoanAchDetail',
  GET_PDC_SECURITY_DETAILS: 'v1/loan/application/cheque-details/leadid/{leadID}/chequetype/{chequeType}',
  POST_PDC_SECURITY: 'v1/loan/application/cheque-details',
  DELETE_PDC_SECURITY: 'v1/loan/application/cheque-details/leadId/{leadID}/chequeId/{chequeId}',


  // Query Management
  GETQUERYLIST_ALL: 'v1/loan/query/all/',
  QUERYASSIGN: 'v1/loan/query/assign',
  GETQUERYLIST: 'v1/loan/query/',
  GETASSIGNMENTLIST: 'v1/loan/query/assignment/',
  QUERYRESPONSE: 'v1/loan/query/response',
  QUERY_EMPLOYEELIST: 'v1/loan/query/user/',

  //Disbursal Condition
  DISBURSALCONDITIONGET:'v1/loan/application/DisbursalSanctionCondition/',
  SANCTIONCONDITIONPOST:'v1/loan/application/sanctionCondition/',
  SANCTIONCONDITION_HISTORY:'v1/loan/application/sanctionCondition/status-history/{loanApplicationID}/{loanApplicationSanctionConditionID}',

  // NDC Checklist
  GETNDCCHECKLIST:'v1/loan/disburse/check-list/type/ndc-check-list/lead/',
  POSTNDCCHCEKLIST:'v1/loan/disburse/check/list/',
  LISTNDCREQUEST:'v1/loan/disburse/check/request/type/ndc-check-list/lead/',
  NDCREFERBACKTOLIST:'v1/loan/disburse/check/refer/user/request/type/ndc-check-list/lead/',

  // NDC Checklist
  GETDOCUMENTCHECKLIST:'v1/loan/disburse/check-list/type/document-check-list/lead/',
  POSTDOCUMENTCHECKLIST:'v1/loan/disburse/check/list/',
  LISTDOCUMENTCHECKLIST:'v1/loan/disburse/check/request/type/document-check-list/lead/',
  DOCUMENTREFERBACKTOLIST:'v1/loan/disburse/check/refer/user/request/type/document-check-list/lead/',

  // DOCKET Checklist
  GETDOCKETCHECKLIST:'v1/loan/disburse/check-list/type/docket-check-list/lead/',
  POSTDOCKETCHECKLIST:'v1/loan/disburse/check/list/',
  LISTDOCKETCHECKLIST:'v1/loan/disburse/check/request/type/docket-check-list/lead/',
  DOCKETREFERBACKTOLIST:'v1/loan/disburse/check/refer/user/request/type/docket-check-list/lead/',


  // login fee
  FEEAPPLICATIONDETAIL: "v1/loan/fee/payment/detail/list/",
  GETFEEDETAILSBYLEADID: 'v1/loan/fee/type/LoginFee/',
  LOGINFEEADDUPDATE: "v1/loan/fee/",
  PAYMENTUPDATE: "v1/loan/fee/payment/detail/",
  PAYMENT: 'v1/loan/fee/payment/detail',
  TRANSFERFROM: "v1/loan/fee/tranfer/from/drop/down/list/{feesType}/{leadID}/{branchID}",
  GETLOGINFEE: "v1/loan/fee/",
  FEETRANSFER: "v1/loan/fee/tranfer/from/1",
  LOGINFEES: "v1/loan/fee/1",
  PAYMENTDETAIL: "v1/loan/fee/payment/detail/2",
  DELLOGINPAYMENT: 'v1/loan/fee/payment/detail/',

  // Document Checklist
  CHECKLIST_ITEMS: 'v1/loan/application/applicant/document/checklist/',

  //insurance
  GETINSURANCEDATA: 'v1/loan/sanction/insurance/',
  POSTINSURANCEDATA: 'v1/loan/sanction/insurance/',
  APPLICANTDETAILS: 'v1/loan/application/applicant/personal/detail/',
  MASTERAGENCIES: 'v1/master/agency/agencyType/',
  PATCH_HEIGHT_WEIGHT: 'v1/loan/sanction/insurance/',
  PREMIUMCALCULATOR: 'v1/premium/calculator/',

  // Under Writing
  DEVIATIONLIST: 'v1/master/deviation/list',
  UNDERWRITINGDATA: 'v1/loan/sanction/underwriting/list/{leadId}/',
  POSTUNDERWRITINGDATA: 'v1/loan/sanction/underwriting/manage/',

  // ROI
  GETROI: 'v1/loan/roi/',
  GETROIWAIVERREQUEST: 'v1/loan/waiver/roi/request/lead/',
  POSTROIWAIVERREQUEST: 'v1/loan/waiver/roi/request/',
  GETROIDETAILS: 'v1/loan/waiver/details/',
  POSTROIAdditionalSpread: 'v1/loan/roi/additional-spread',

  // ROI Waiver
  GETROIWAIVERDETAILS: 'v1/loan/waiver/detailsByUser/',
  POSTROIWAIVERDETAILS: 'v1/loan/waiver/roi/request/update/',


  // CAM
  GETCAM: 'v1/loan/application/cam/',
  ASSIGNTO: 'v1/master/lead/',

  // Waiver
  GETWAIVER: 'v1/loan/waiver/detail/',
  POSTWAIVER: 'v1/loan/waiver/manage/',

  // Loan Acceptance
  GETLOANACCEPTANCE: 'v1/loan/application/applicationStatusHistory/',
  POSTLOANACCEPTANCE: 'v1/loan/application/fileAcceptance/',

  // Submit Application
  POSTSUBMITAPPLICATION: 'v1/lead/submit/',

  // Documents
  GETREJECTIONLETTER:'v1/reports/rejectionReport',
  GETSANCTIONLETTER:'v1/reports/sanctionReport',
  GETCAMLETTER:'v1/reports/camReport',
  GETACHREPORT:'v1/reports/achReport',
  GETCODE: "v1/master/configvalue/DeleteAllowOnDocumentCode",

  // technical report
  GETTECHNICALRPT:'v1/report/technical/get/{leadID}',
  POSTTECHNICALRPT:'v1/report/technical/manage',
  POSTSUBSEQUENTRPT:'v1/report/technical/subsequentReport/manage',
  POSTTECHNICALVETTING:'v1/report/technical/vetting/manage/{leadID}',
  GET_DOC_BASE64:'v1/document/download/base64/application-document/{docID}',
  POST_PROPERTY_DEDUPE:'v1/report/technical/property/list',
  UPDATE_TECHNICAL_IMAGES: 'v1/report/technical/update',
  TECHNICAL_RPT_PRINT: 'v1/report/technical/download',
  POSTSUBSEQUENTRPT_NEW:'v2/report/technical/subsequentReport/manage',
  GET_TECHNICAL_RPT_NEW: 'v2/report/technical/get/{leadID}',
  TECHNICAL_RPT_REOPEN: 'v2/report/technical/vetting/reopen/{leadID}/{firingID}',
  POSTTECHNICALVETTING_NEW:'v2/report/technical/vetting/manage/{leadID}',
  DOWNLOAD_REPORT: 'v2/report/technical/download-document/{leadID}/{firingID}',
  SIGNED_REPORT_UPLOAD: 'v1/document/technical/upload',

  //Lead Assignment
  GETLEADASSIGNMENT:'v1/leadAssignments/',
  // ASSIGNLEADASSIGNMENT:'v1/task/',



  // need to be categories as per url

  UPDATESALARYINC: 'v1/loan/application/applicant/income/salary',
  UPDATEITR: 'v1/loan/application/applicant/income/financialitr',
  LIANDASSET: 'v1/loan/application/applicant/liability/',
  ROLEINFO: 'v1/role/',
  MODULEPRIVILIGEINFO: 'v1/role/',
  MODULEPOSTPRIVILIGE: 'v1/role/privilege/',
  EMPLOYEEINFO: 'v1/employee/',
  COMPONENTPRIVILIGEINFO: 'v1/component/role/',
  REFERENCEDETAIL: 'v1/loan/application/reference/detail/',
  ADDREFERENCE: 'v1/loan/application/reference/detail/list/',
  LIBILITYASSET: 'v1/loan/obligation/card/asset/reference/detail/',
  ADDPROPERTY: 'v1/loan/sanction/property/detail',
  GETPROPERTY: 'v1/loan/sanction/property/detail/',
  DOCUMENTUPLOAD: 'v1/file/upload',
  POSTEMPLOYMENTDETAIL: 'v1/loan/application/employment/',
  UPLOAD_DOCUMENT: 'v1/document/upload/',
  POSTCPVRCUDATA: 'v1/loan/sanction/cpv/rcu/verification/',
  POST_FIRING_DATA: 'v1/loan/technical/firing/',
  STATUS_HISTORY: 'v1/loan/technical/firing/status/history/loan/{loanApplicationID}/firing/{firingID}',
  STATUS_HISTORY2: 'v1/loan/legal/firing/status/history/loan/{loanApplicationID}/firing/{firingID}',
  FIRING_REASSIGNLEGAL: 'v1/loan/legal/firing/re-assign/',
  FIRING_REASSIGN: 'v1/loan/technical/firing/re-assign/',
  CALCULATE_ELIGIBILITY: 'v1/actico/ruleBaseEligibility/',
  POST_ROLE_PRIVILEGE: 'v1/role/privilege/',
  POST_COMPONENT_PERMISSION: 'v1/component/role/',
  COMPONENT_PRIVILIGE_DATA: 'v1/component/role/',
  ALL_EMPLOYEE_PAGINATION:'v1/employee/list',

  // GET


  GETSALARYINCOME: 'v1/loan/application/applicant/income/salary/5',
  GETITRINCOME: 'v1/loan/application/applicant/income/itr/5',

  GETAPPLICANTDATA: "v1/loan/application/employment/",
  GETAPPLICATDATABYLEADID: 'v1/loan/applicant/income/all/lead/{leadId}',
  SAVEINCOMEDATABYAPPLICANTID: 'v1/loan/applicant/income/all',
  GETLIABANDASSET: 'v1/loan/obligation/card/asset/reference/detail/',
  REFERENCEDETAILS: 'v1/loan/application/reference/detail/list/',
  DOCUMENTDOWNLOAD: 'v1/document/application-document/',
  COMPANYLIST: 'v1/master/auth/companies/',
  CPVRCUDATALIST: 'v1/loan/sanction/cpv/rcu/verification/',
  AGENCYBYBRANCHID: 'v1/master/agency/AgencyType/CpvAgency/',
  AGENCYBYMODULE: 'v1/master/agency/AgencyType/CpvAgency/{branchId}/AgencyModuleType/{AgencyModuleTypeCode}',
  TECHNICAL_AGENCY_BY_BRANCHID: 'v1/master/agency/AgencyType/TechnicalAgency/',
  ACTICORECORD: 'v1/loan/application/rule-engine-response/',
  FLIP_RECORD: 'v1/actico/flip/',
  DOCUMENT_AND_TYPE_LIST: 'v1/document/',
  GET_FIRING_DOCUMEMNT: 'v1/loan/technical/firing/',
  GET_DOCUMENT_LIST_BY_NAME: 'v1/document/',
  GET_DOCUMENT_LIST_OTHER: 'v2/document/',
  GET_ROLE_LIST:'v1/role/',


  // Dropdowns


  // Delete
  DELETESECOUNDRYINCOME: 'v1/loan/applicant/income/secondary/{secoundryID}',
  DELREFERENCE: 'v1/loan/application/reference/detail/',
  DELOBLIGATE: 'v1/loan/application/applicant/liability/existing/obligation/',
  DELCREDIT: 'v1/loan/application/applicant/liability/credit/card/',
  DELASSET: 'v1/loan/application/applicant/liability/asset/detail/',
  DELDOCUMENT: 'v1/document/application-document/',

  // Update
  ASSETUPDATE: "v1/loan/application/applicant/liability/asset/detail/",
  CREDITUPDATE: "v1/loan/application/applicant/liability/credit/card/",
  OBLIUPDATE: "v1/loan/application/applicant/liability/existing/obligation/",

  //permissionx
  APPLICANTINFO: "v1/loan/applicant/",
  BANKINFO: "v1/loan/application/applicant/bank-details",
  INCOME_PREDICTION: "v1/account/aggregator/income/model/income-prediction/{leadID}/{applicantID}",
  DAILY_TRANSACTION: "v1/document/cart/transactions",

  // Additional Bureau
  POST_ADDITIONAL_BUREAU: "v1/bureau/additional/details/",
  GET_CIBIL_SCORE: "v1/bureau/additional/triggerCibil/",
  GET_EXPERIAN_SCORE: "v1/bureau/additional/triggerExperian/",
  DELETE_ADDITIONAL_BUREAU_RECORD: "v1/bureau/additional/details/",

  // Users
  GET_ALL_USER: "v1/user/",
  GET_ALL_USER_TYPE: "v1/master/user-types/",
  GET_ALL_AGENCY_USER: 'v1/master/agency-management/agencies/subtype/{agencyTypeDetailId}',
  GET_ALL_DSAUSER: 'v1/dsa/',
  GET_USER_BY_USER_TYPE_CODE: "v1/user/type/",
  ALL_USER: "v1/user/",
  ALL_USER_PAGINATION: "v1/user/list",
  USER_DETAILS: "v1/user/detail/{userID}",

  // Guarantor
  POSTGUARANTORDETAIL: 'v1/loan/sanction/guarantor/detail/',
  GUARANTORLIST: 'v1/loan/sanction/guarantor/list/',
  GET_GUARANTOR_CIBIL: 'v1/loan/sanction/guarantor/triggerCibil/',
  GET_GUARANTOR_EXPERIAN: 'v1/loan/sanction/guarantor/triggerExperian/',
  GET_UID: 'v1/aadhaar/by/reference/',
  // GET_ALL_DEDUPES: 'v1/loan/de-dupe/for/{dedupeForTypeDetailID}',
  // UPDATE_GAURANTOR_DEDUPE: 'v1/loan/de-dupe/remarks/{deDupeID}',


  // Lead Creation
  GET_ALL_LEADS_PAGINATION: "v1/pagination/lead/find",
  GET_ALL_LEADS: "v1/lead/getAllCreatedByLeads/",
  CREATE_MULTIPLE_LEADS: "v1/lead/multipleLead/",
  DELETE_LEAD: "v1/lead/",

  // Forgot Password
  GET_OTP: "v1/auth/forgot/getOtp/",
  VERIFY_OTP: "v1/auth/forgot/otpVerify/",
  RESET_PASSWORD: "v1/auth/forgot/submit/",

  // Work Mapping
  WORK_MAPPING_WORK_ROLE: "v1/user/allUserWorkRole/",
  WORK_MAPPING_MULTIPLE_QUEUE_SELECTION: "v1/user/allQueue/",
  WORK_MAPPING_SPECIAL_PERMISSION: "v1/user/specialPermissions/",
  DELETE_SPECIAL_PERMISSION: "v1/user/deleteSpecialPermission/",
  GET_WORK_ROLE_LIST: "v1/user/allWorkRoles/",
  GET_MULTIPLE_QUE_SELECTION_LIST: "v1/user/queueMapping/",
  GET_SPECIAL_PERMISSION_LIST: "v1/user/userSpecialPermissions/",
  POST_WORK_ROLE_LIST: "v1/user/userWorkRole/",
  POST_MULTIPLE_QUE_SELECTION_LIST: "v1/user/userQueueDetail/",
  POST_SPECIAL_PERMISSION_DATA: "v1/user/saveSpecialPermission/",
  POST_SPECIAL_APPROVAL_DATA:"v1/user/userSpecialApproval/",
  GET_SPECIAL_APPROVAL_DATA: "v1/user/specialApproval/",


  // Face Matching Request
  POST_FACE_MATCHING_REQUEST: "v1/kyc/face-match/request/",
  UPDATE_FACE_MATCHING_REQUEST: "v1/kyc/face-match/request/update/",
  GET_REQUEST_LIST: "v1/kyc/face-match/request/lead/",

  // Logout
  LOGOUT_USER: "v1/user/logout/",

  // Lead Report
  POSTLEADREPORT:"v1/reports/download",

  // Forward LMS
  GETDISBURSAL:"v1/vToG/",
  POSTDISBURSAL:"v1/vToG/w2g/",

  // DashBoard
  GETLOGINDASHBOARD:"v1/dashboard/web/Login",
  GETSANCTIONDASHBOARD:"v1/dashboard/web/Sanction",
  GETDIBSURDALDASHBOARD:'v1/dashboard/web/disbursement',
  GETZONEDASHBOARD:'v1/dashboard/zone',
  GETREGIONDASHBOARD:'v1/dashboard/reson/',
  GETBRANCHDASHBOARD:'v1/dashboard/branch/',
  GETFOSDASHBOARD: 'v1/dashboard/summary/branch/{branchID}',
  DSA_DASHBOARD: 'v1/dsa/dashboard/{screen}',

  // Additional Activity
  POST_PROPERTY_VISIT:"v1/loan/application/property-visit/",

  // Master Droddown
  POSTTYPEDETAIL:"v1/master/type-detail",
  POSTTYPEMASTER:"v1/master/type-master",
  GETMASTERSALL:"v1/master/all-master/",

  // KYC
  // PERFORM_KYC_LINK: "http://13.235.28.32/kyc/kyc.html?kycID="                        // OLD-DEV
 // PERFORM_KYC_LINK: "https://rapid-dev.dmihousingfinance.in/kyc/kyc.html?kycID=",     // DEV
    PERFORM_KYC_LINK: "https://rapid-uat.dmihousingfinance.in/kyc/kyc.html?kycID=",    // UAT
  // PERFORM_KYC_LINK: "https://rapid.dmihousingfinance.in/kyc/kyc.html?kycID="         // PROD

  // disbursal Balance Fees
  GETBALANCE_FEES: 'v1/loan/fee/feeType/ProcessingFee/',
  POST_FEE_DETAILS:"v1/loan/fee/detail/save/",
  POST_PAYMENT_DETAILS:"v1/loan/fee/add/processingFee/payment/detail",

  // disbursal deviation
  GET_DEVIATION_DETAIL:"v1/loan/application/document/deviationDetail/{leadID}",
  GET_DEVIATION_LIST:"v1/loan/application/DisbursalSanctionCondition/disbursalDeviation/list",
  POST_DEVIATION_DATA:"v1/loan/application/sanctionCondition/loanApplicationDisbursalDeviation",

  // post disbursal payment status update
  GET_PAYMENT_DETAIL:"v1/loan/application/disbursement/payment/collection/details/cheque-type",
  POST_PAYMENT_DETAIL:"v1/loan/application/disbursement/payment/collection/update/status",
  GET_DISBURSEMENT_BATCH_DETAIL:'v1/disbursementBatch/document',
  DOWNLOAD_DISBURSE_FILE: 'v1/document/download/',

  // disbursal memo
  GET_DISBURSAL_MEMO:"v1/loan/disburse/memo/{leadID}",
  POST_DISBURSAL_MEMO_STATUS:"v1/loan/disburse/memo/disbursal/{leadID}",
  OTC_PDD: "v1/loan/disburse/otc-pdd/{loanApplicationID}",

  // disbursal legal Document
  GET_DOC_NAME:"v1/loan/legal/document/list",
  GET_LEGAL_DOC:"v1/loan/application/document/{leadID}",
  POST_LEGAL_DOC:"v1/loan/legal/document/manage",
  GET_STATUS_HISTORY:"v1/loan/application/document/history/{documentID}",
  POST_STATUS_HISTORY:"v1/loan/legal/document/updateStatus",


  // legal firing
  GET_LEGAL_FIRING:"v1/loan/legal/firing/{leadID}",
  POST_LEGAL_FIRING:"v1/loan/legal/firing/{leadID}",
  LEGAL_AGENCY_BY_BRANCHID: 'v1/master/agency/AgencyType/LegalAgency/',

  // legal report

  GET_LEGAL_RPT:'v1/report/legal/get/{leadID}',
  NEW_GET_LEGAL_RPT:'v2/report/legal/get/{leadID}/firingID/{firingID}',
  POST_LEGAL_RPT:'v1/report/legal/manage',
  NEW_POST_LEGAL_RPT:'v2/report/legal/manage',
  POST_LEGAL_VETTING:'v1/report/legal/vetting/manage/{leadID}',
  NEW_POST_LEGAL_VETTING:'v2/report/legal/vetting/manage/{leadID}',


  // Loan Processing

  GET_LOANPROCESSING:'v1/disbursal/loanProcessing/get/',
  POST_LOAN_PROCESSING:'v1/disbursal/loanProcessing/disbursaldetail',
  PUT_VERIFY_FAVOURING:'v1/disbursal/loanProcessing/favouringverification',
  GET_FAVOURING_CATEGORY:'v1/master/disb-fav-details/',
  ACTICO_DISBURSEMENT_POLICY:'v1/actico/disbursement/policy',

  // Manual Receipt
  GET_TABLE_LISTING: 'v1/loan/manual/payment/detail/list/{leadID}',
  GET_PAYMENT_DETAILS: 'v1/loan/manual/payment/detail/{receiptID}',
  // POST_MANUAL_PAYMENT_DETAILS: 'v1/loan/manual/payment/detail/{leadID}',
  POST_MANUAL_PAYMENT_DETAILS: 'v1/loan/manual/receipt/detail/{leadID}',
  BULK_RECEIPT :'v1/loan/manual/receipt/bulk-detail',
  DEL_RECEIPT : 'v1/loan/manual/payment/detail/',

  // lead management
  ASSIGN_TO_FOS_UPDATE: 'v1/loan/application/draft/change-storage/lead',
  POST_CALL_UPDATE: 'v1/lead/followUp/{leadID}',
  GET_CALL_UPDATE_HISTORY: 'v1/lead/followUp/{leadID}',
  GET_LEAD_DATA:'v1/pagination/lead/find',
  GET_LEAD_LOAN_DATA: 'v1/loan/application/draft/lead/{leadID}/type/LOAN_INFORMATION_C/',
  GET_LEAD_PERSONAL_DATA: 'v1/loan/application/draft/lead/{leadID}/type/APPLICANT_PERSONAL_C/',
  GET_LEAD_INCOME_DATA: 'v1/loan/application/draft/lead/{leadID}/type/APPLICATION_EMPLOYMENT_C/',

  // ocr screen
  GET_OCR: 'v1/disbursal/ocrdetail/get/{leadID}',
  POST_OCR: 'v1/disbursal/ocrPaymentDetails',
  GET_OCR_FI: 'v1/loan/sanction/OcrFi/verification/{leadID}',
  POST_OCR_FI: 'v1/disbursal/ocr/agencyfi/verification',
  UPDATE_FINALOCR_STATUS: 'v1/disbursal/',
  UPDATE_INTERNALQUERY: 'v1/disbursal/ocr/internal-comment/',

  // presentation & collection
  GET_BANK_DEPOSITE_WITH: 'v1/postdisbursal/presentation/deposit-with-bank/all',
  GET_BATCH_LIST: 'v1/postdisbursal/presentation/get',
  GET_BATCH_DATA: 'v1/postdisbursal/presentation/getSelectedBatch',
  GET_BATCH_SEARCH: 'v1/postdisbursal/presentation/getPresentationDetail',
  POST_BATCH_DATA: 'v1/postdisbursal/presentation/presentationdetail',
  POST_BATCH_STATUS_DATA: 'v1/postdisbursal/presentation/updateStatus',

  // allocation charges
  POST_ALLOCATION_CHARGE: 'v1/allocateCharge/manage',
  GET_ALLOCATION_CHARGE: 'v1/loan/disburse/charges/{leadID}',
  GET_CHARGE_MASTER: 'v1/master/charge',

    // disbursal cheque
    PUT_DISBURSAL_CHEQUE: 'v1/disbursal/chequeneft/processChequeNeftDetail',
    GET_DISBURSAL_CHEQUE: 'v1/disbursal/chequeneft/getChequeNeftDetail/',

  // batch generation
  GET_BATCH_GENERATION: 'v1/disbursementBatch/get',
  POST_BATCH_GENERATION: 'v1/disbursementBatch/manage',

  //DSA
    GET_DSA_LIST:'v1/dsa/',
    GET_DSA_DETAILS:'v1/dsa/{dsaID}',
    DELETE_DSA:'v1/dsa/{dsaID}',
    POST_DSA:'v1/dsa/addDsa',
    GET_DSA_BRANCH:'v1/dsa/branch/{dsaID}',
    GET_DSA_Employee:'v1/dsa/employee/{dsaID}',
    DSA_BRANCH_EMPLOYEE_MAPPING:'v1/dsa/add/EmployeeAndBranch/{dsaID}',
    GET_DSA_BY_BRANCH: 'v1/dsa/employee/{dsaId}/branch/{branchID}',
    // NACH
    GET_NACH:'v1/loan/application/ach/getACHDetail',
    POST_NACH:'v1/loan/application/ach/addLoanAchDetail',
    UPDATE_NACH:'v1/loan/application/ach/updateACHDetail',

    // loan cancellation
    GET_LOAN_CANCELLATION:'v1/loan/cancellation/get/{leadID}',
    POST_LOAN_CANCELLATION:'v1/loan/cancellation/processloan',

    // loan Summary
    GET_LOAN_SUMMARY:'v1/customerMobileNoSubmit/loanDetails/loanApplication/rapid/lead/{leadID}',

    // loan reschedulement
    POST_LOAN_RESCH:'v1/loan/reschedule/manage',
    PUT_LOAN_RESCH:'v1/loan/reschedule/updateStatus',
    GET_LOAN_RESCH:'v1/loan/reschedule/getLoanReschedule/{leadID}',
    GET_LOAN_RESCH_MANUAL_RECEIPT:'v1/loan/reschedule/getManualReceipt/{leadID}',
    GET_LOAN_RESCH_DETAILS:'v1/loan/reschedule/get',
    GET_IMPACT_DETAILS:'v1/loan/reschedule/reschedulement-impact',


    // Add Branch
    POSTADDBRANCH:'v1/master/branch',

    // subsequent disbursment
    GET_SUBSEQ_DIS:'v1/disbursal/subsequent/loanapplicationid/{loanApplicationID}',
    GET_SUBSEQ_DIS_DET:'v1/disbursal/subsequent/',
    POST_SUBSEQ_DIS_DET:'v1/disbursal/subsequent',
    //cheque Handover
    GETCHEQUEHANDOVER:'v1/loan/cheque/handover/',
    POSTCHEQUEHANDOVER:'v1/loan/cheque/handover',
    HANDOVER_HISTORY:'v1/loan/cheque/disbursal-detail/history/{issueId}',
    SAVE_HANDOVER:'v1/loan/cheque/disbursal-detail/save',
    TOWHOM_MASTER:'v1/loan/cheque/cheque-handover/{loanPurposeID}',

    // service request
    GET_SERVICE_REQUEST:'v1/portal/salesforce/case/details',
    POST_SERVICE_REQUEST:'v1/portal/salesforce/case',
    PUT_SERVICE_REQUEST:'v1/portal/salesforce/case/caseUpdateAPI',
    POST_SERVICE_REQUEST1:'v1/portal/salesforce/case/serviceRequest',
    GET_SERVICE_REQUEST_COMMENTS: 'v1/portal/salesforce/case/allDetails',
    GET_DOCUMENT_LISTS:'v1/document/applicationDocumentList/{documentIds}',

    // correspondence Address
    GET_CA_REQUEST:'v1/loan/application/applicant/personal/detail/correspondance/{leadID}',
    POST_CA_REQUEST:'v1/loan/application/applicant/personal/detail/correspondance/{leadID}',
    // foreclosure
    GET_FORECLOSURE:'v1/loan/foreclosure/{leadID}',
    POST_FORECLOSURE:'v1/loan/foreclosure/manage',

    // manage Agency
    GET_AGENCY_BYTYPE:'v1/master/agency-management/agencies/subtype/{agencyTypeDetailID}',
    GET_AGENCY_DATA:'v1/master/agency-management/agencies/{agencyID}',
    GET_AGENCY_BRANCH:'v1/master/agency-management/agencies/branches/{agencyID}',
    GET_AGENCY_ADJUSTMENT:'v1/loan/foreclosure/foreclosure-adjust',
    POST_AGENCY:'v1/master/agency-management/agencies',
    POST_AGENCY_BRANCH:'v1/master/agency-management/agencies/branches/{agencyID}',
    DELETE_AGENCY:'v1/master/delete/agency/{agencyID}',
    POST_AGENCY_USER: 'v1/master/agency-management/agencies/user-branches/{agencyID}',

    // leegality
    GET_DOCS_STS_LIST:'v1/loan/document/esign/request/leadID/{leadID}/documentName/{documentTypedetailCode}',
    GENERATE_DOCS:'v1/loan/document/esign/signing-request/{leadID}/document-name/{documentTypedetailCode}',
    DOC_STATUS: 'v1/loan/document/merge/{leadID}/{documentCodeTypeDetailCode}/{signType}',
    DOC_PREPARE: 'v1/loan/document/merge',
    DOC_APPROVAL: 'v1/loan/document/merge',
    DELETE_STAMP: 'v1/loan/document/esign/{leadID}',
    PURCHASED_STAMP_DOC: 'v1/loan/document/esign/sign-request',
    // batch processing
    GET_BATCHFILE: 'v1/loan/reschedule/batch/files',
    GET_BATCHFILE_PAGINATE: 'v1/loan/reschedule/batch/pagination/file-details',
    PROCESS_BATCHFILE: 'v1/loan/reschedule/batch/manage',
    IMPACT_BATCHFILE: 'v1/loan/reschedule/batch/impact-detail/{headerID}',
    IMPACT_BATCHFILE_PAGINATE: 'v1/loan/reschedule/batch/pagination/impact-detail',
    STATUS_BATCHFILE: 'v1/loan/reschedule/batch/check-status',
    DELETE_BATCHFILE: 'v1/loan/reschedule/batch/file/{headerID}',
    DELETE_BATCHFILE_DETAILS: 'v1/loan/reschedule/batch/file-records',

    // loan agreement
    GET_AGREEMENT: 'v1/loan/agreement/{leadID}',
    POST_AGREEMENT: 'v1/loan/agreement/add',

    // letters kit
    GET_LETTERS: 'v1/loan/document/merge/sign-document/{leadID}',

    //property valuation
    GET_PROPERTY_VAL:"v1/loan/property/valuation/{loanApplicationID}",
    POST_PROPERTY_VAL: 'v1/loan/property/valuation',

    // draft dsa
    GET_DRAFT_DSA_LIST: 'v1/dsa/request',
    GET_DRAFT_DSA_PAGINATOR: 'v1/dsa/request/list',
    GET_DRAFT_DSA: 'v1/dsa/request/{dsaRequestID}',
    POST_DRAFT_DSA: 'v1/dsa/request/',
    DELETE_DRAFT_DSA: 'v1/dsa/request/{dsaRequestID}',
    STATUS_DRAFT_DSA: 'v1/dsa/request/{dsaRequestID}/status',
    GET_FI_DRAFT_DSA: 'v1/loan/sanction/agencyfi/verification/{draftDsaID}',
    POST_FI_DRAFT_DSA: 'v1/loan/sanction/agencyfi/verification/process',
    REJECTED_DSA: 'v1/dsa/request/re-initiate',
    POST_PAYOUT_DATA:'v1/dsa/request/{dsaRequestID}/status',
    PUT_PAYOUT_DATA:'v1/dsa/request/{dsaRequestID}/status',



    GENERATE_CIBIL_DRAFT_DSA: 'v1/bureau/Agency/{type}/{refCode}',
    ADD_PERMISSION_DSA: 'v1/dsa/addPermission',
    DSA_DOCUMENT_AND_TYPE_LIST: 'v2/document/',
    DSA_GET_DOC_DETAILS: 'v1/document/prepare/dsa/',
    DSA_GET_EMPLOYEE_LIST: 'v1/master/getQueName',
    DSA_E_SIGN_REQUEST: 'v1/dsa/document/esign/request/refRecordCode/{dsaCode}/documentName/{docName}',
    DSA_E_SIGN_REQUEST_POST: 'v1/dsa/document/esign/signing-request/{dsaCode}/document-name/{docName}/employeeEntityID/{empId}',
    DSA_PREPARE_DOC: 'v1/document/prepare/dsa',
    DSA_ESIGN_BASE64: 'v1/document/download/base64/application-document/{docId}',
    STAMP_UPLOAD_DETAILS: 'v1/loan/document/esign/upload',
    GET_ALL_STAMP_DETAILS: 'v1/loan/document/esign/getAllStampUpload',



    //source funding
    GET_ALLBATCHES: 'v1/sourcefunding/all/',
    POST_BATCHES: 'v1/sourcefunding/',
    GET_PARTICULAR_BATCH:'v1/sourcefunding/{batchID}',
    GET_FUNDINGLIST:'v1/sourcefunding/allocation/{batchID}',
    POST_BATCHE_STATUS: 'v1/sourcefunding/',
    POST_SCHEDULER: 'v1/sourcefunding/schedule/save/',

    //
    GET_SELLER_BUILDER:'v1/loan/sanction/seller-builder/{loanApplicationID}',
    POST_SELLER_BUILDER: 'v1/loan/sanction/seller-builder/process/',
    GET_SELLER_BUILDER_FI: 'v1/loan/sanction/agencyfi/verification/Seller/{sellerBuilderID}',
    POST_SELLER_BUILDER_FI: 'v1/loan/sanction/agencyfi/verification/process/',


    // bankingModel
    GET_BANKING_MODELDETAILS: 'v1/account/aggregator/banking-model/{leadID}/{leadApplicantNumber}',

    //cronjob
    FIND_ALL_CRONJOB: 'v1/scheduler/findAll',
    POST_CRONJOB: 'v1/scheduler/create',
    RESUME_CRONJOB: 'v1/scheduler/job-group/{jobGroup}/job/{jobName}/resume',
    PAUSE_CRONJOB: 'v1/scheduler/job-group/{jobGroup}/job/{jobName}/pause',

    //PD List
    PD_LIST : 'v1/loan/sanction/pdreport/status',
    VIEW_PD_LIST : 'v1/loan/sanction/pdreport/history/applicant/',
    PD_REINITIATE : 'v1/pd/application/draft/reInitiate/{leadID}/applicantID/{applicantID}',

    //Change Favouring List
    GET_FAVOURINGCHANGE: 'v1/loan/fav-change/{leadID}',
    GET_FIRST_FAVOURINGCHAMGE: 'v1/loan/fav-change/details/',
    POST_FAVOURINGCHANGE: 'v1/loan/fav-change/process/',

    //borrowers refund
    REFUND_LIST: 'v1/loan/refund/loan-refund/{leadID}',
    GET_EDIT_REFUND:'v1/loan/refund/detail',
    GET_NEW_REFUND:'v1/loan/refund/detail',

    POST_REFUND:'v1/loan/refund/manage',
    PUT_REFUND:'v1/loan/refund/favoring-verify',

    // bulk ach emandate
    GET_ACH_EMANDATE:'v1/loan/application/ach/emandate/getACHDetail',
    POST_ACH_EMANDATE:'v1/loan/application/ach/bulk/emandateInitiation',

    // ReKYC

    GET_KYC_DETAILS:'v1/loan/rekyc/data/{leadID}',

    // Loan waiver
    GET_LOAN_WAIVER_LISTING:'v1/loan/waiver/{leadID}',
    GET_LOAN_WAIVER_DETAILS:'v1/loan/waiver/detail/',
    POST_LOAN_WAIVER_DETAILS:'v1/loan/waiver/process',


    // Document Sign

    GET_APPLICANT_NAME:'v1/loan/applicant/{leadID}',
    PUT_APPLICANT_NAME_AS_PER_AADAHR:'v1/loan/applicant/{leadID}',

    //Customer Journey
     GET_CUSTOMER_JOURNEY:'v1/customerjourney/{leadID}',

    // empTaskMgnt
    GET_TASK_COUNT:'v1/employee-task-management/employee-task-summary',
    GET_TASK_DETAILS:'v1/employee-task-management/employee-task-details',
    GET_ZONE_REGION_BRANCH:'v1/employee-task-management/branch-zone-region-names',
    GET_PIC_DSA:'v2/document/{docID}',

    // get image in pdf
    POST_IMAGES_PDF:'v1/application-form/download',

    // Payout Update
    GET_PENDING_PAYOUTS: 'v1/loan/payout/paymentUpdate/allPending',
    GET_CLARIFICATION_PAYOUTS:'v1/loan/payout/allPartner/payoutPendingDetails',
    POST_PENDING_PAYOUTS: 'v1/loan/payout/update/allPending',
    GET_DSA_PAYOUT: 'v1/loan/payout/allPartner/payoutDetails',
    POST_VERIFICATION: 'v1/loan/payout/verification',
    GET_CLARIFICATION_PAYOUT:'v1/loan/payout/portal/dsa/partner-payout-clarifications',
    PAYOUT_CLARIFICATION_SUBMIT:'v1/loan/payout/partner-payout-clarifications',
    GET_VIEW_PAYOUT: 'v1/loan/payout/payout-payment-details',
    GET_DETAILED_PAYOUT: 'v1/loan/payout/portal/dsa',
    CLOSE_QUERY: 'v1/loan/payout/close/partner-payout-clarifications',

    // //ALM/ATM dashboard
    // GET_ALM_COUNT:'v1/dashboard/technicalVettingTatSummary',
    // GET_TAT_DETAILS:'v1/dashboard/technicalVettingTatDetail',


    // NOC Letter
    GET_NOC : 'v1/loan/document-tracking/get/{loanApplicationID}',
    POST_NOC : 'v1/loan/document-tracking/process',
    //Property Esign
    GET_PROPERTY_ESIGN:'v1/loan/document/esign/request/leadID/{leadID}/documentName/PropertyVisitReportEsign',
    // POST_PROPERTY_ESIGN:'v1/loan/document/esign/property-visit-sign-request/{leadID}/document-name/PropertyVisitReportEsign/employeeEntityID/{employeeEntityID}',
    POST_PROPERTY_ESIGN:'v1/loan/document/esign/property-visit-sign-request',

    //ALM/ATM dashboard
    GET_ATM_COUNT:'v1/dashboard/technicalVettingTatSummary',
    GET_TAT_DETAILS:'v1/dashboard/technicalVettingTatDetail',
    GET_ALM_COUNT:'v1/dashboard/legalVettingTatSummary',
    GET_TAT_DETAILS2:'v1/dashboard/legalVettingTatDetail',

    //cart
    GET_CARTSTATUS: 'v1/document/cart/status',
    GET_DETAILED_NOVEL: 'v1/document/cart/all-details',

    //Employer Profile (Karza)
    GET_EMPLOYER_PROFILE_THROUGH_PAN:'v1/loan/karza/pan-details/lead/{leadID}/leadApplicantNumber/{leadApplicantNumber}',
    GET_EMPLOYER_PROFILE_THROUGH_MOBILE:'v1/loan/karza/uan-details/lead/{leadID}/mobile/{mobile}/applicant-number/{leadApplicantNumber}',

    //PAN Profile
    GET_PAN_PROFILE:'v1/loan/karza/get/profile/{leadID}/pan/{PAN}/leadApplicantNumber/{leadApplicantNumber}',

    // CPU Managers Dashboard
     GET_CPU_SCREEN_DATA : 'v1/dashboard/cpu/{screen}',

     // Technical Firing with firing id
     GET_TECHNICAL_REPORT_WITH_FIRINGID:'v2/report/technical/get/{leadID}/firing/{firingID}',
     POST_TECHNICAL_FIRING_RPT:'v2/report/technical/manage',
     POST_SUBSEQUENT_RPT:'v2/report/technical/subsequentReport/manage',
     GET_REJECTION_LIST: 'v2/report/technical/lead/firing/{leadID}/{firingID}',
     CP_GET_ALL_STAMP_DETAILS: 'v1/channel-partner/esign/stamp/all',
     CP_POST_STAMP_UPLOAD: 'v1/channel-partner/esign/stamp/upload',
     CP_DELETE_STAMP: 'v1/channel-partner/esign/stamp/{stampDocumentID}',
     CP_POST_PREPARE_DOC: 'v1/document/prepare/channel-partner',
     CP_GET_PREPARED_DOC: 'v1/document/prepare/channel-partner/{dsaCode}/document-name/{docName}',
    //  CP_POST_E_SIGN_REQUEST: 'v1/channel-partner/esign/signing-request/{dsaCode}/document-name/{docName}/employeeEntityID/{empId}',
     CP_POST_E_SIGN_REQUEST: 'v1/channel-partner/esign/signing-request',
     CP_GET_E_SIGN_REQUEST: 'v1/channel-partner/esign/{dsaCode}/document-name/{docName}',
     CP_POST_MANUAL_SIGN: 'v1/channel-partner/esign/manual',
     CP_DELETE_FULL_ESIGN: 'v1/channel-partner/esign/delete/{refRecordCode}/document-name/{documentName}',

     // Config API for Nupay
     CONFIG: 'v1/master/configvalue/nupayServiceProviderBank',

     //MFA
     MFA_OTP: 'v1/auth/mfu/verify',

     // support screen
     POST_UNBLOCK_USER:'v1/user/unblock/usr',

     // insurance policy status update
     GET_INSURANCE_POLICY:'v1/insurance/favoring/get',
     POST_INSURANCE_POLICY:'v1/insurance/favoring/update/insurance/favouring',

    // DCB
    GET_PARTNER_LIST:'v1/aadhaar/all/patner',
    GET_DCB_LIST:'v1/aadhaar/get/dcb/ln/dtl/',
    PATCH_DCB:'v1/aadhaar/prtfo/ptnr/update',

    // inorganic dashboard
    GET_ZONE_DATA:'v2/orgDashboard/zone/',
    GET_REGION_DATA:'v2/orgDashboard/reson/',
    GET_BRANCH_DATA:'v2/orgDashboard/branch/',
    GET_FOS_DATA:'v2/orgDashboard/summary/branch/{branchID}',

    //PD Recording
    ACQUIRE_RESOURCE_ID:'v1/agora/recording/acquire',
    START_RECORDING:'v1/agora/recording/start',
    STOP_RECORDING:'v1/agora/recording/stop',

    // builderApf
    GET_BUILDERAPFLIST_DATA:'v1/builder/apf/',
    GET_BUILDER_DATA:'v1/builder/',
    POST_BUILDER_DATA:'v1/builder/',
    GET_BUILDERAPF_DATA:'v1/builder/apf/{builderApfID}/',
    POST_BUILDERAPF_DATA:'v1/builder/apf/',
    DELETE_BUILDERAPF_DATA:'v1/builder/apf/{builderApfID}',
    POST_BUILDERAPF_STATUS_DATA:'v1/builder/apf/status',
    GET_BUILDERAPF_CREDIT_MANAGER:'v1/employee/branch/{branchID}/work-role/ApfCreditManager/',

    POST_BUILDERAPF_FIRING_REPORT:'v1/builder/apf/{builderApfID}/fi/process',

    // pre approved loans
    GET_APPROVED_BATCH_LIST:'v1/loan/topup-workflow/get/batch-list',
    POST_BATCH:'v1/loan/topup-workflow/save/batch-list',
    GET_IDENTIFIED_LOANS:'v1/loan/topup-workflow/get/batchId/{batchId}',
    POST_IDENTIFIED_LOANS:'v1/loan/topup-workflow/save/eligible/top-up',

    // PD Report Screen
    GET_PD_REPORT_MEETING_LIST:'v1/meeting/schedule/{leadID}',

    //PD Assigned Screen
    GET_PD_ASSIGNED_VIDEO_LIST:'v1/agora/recording/videos/meeting/{meetingId}',


    //Delinquency Dashboard
    GET_DELINQUENCY_ZONE_DATA:'v1/delinquency-dashboard/zone/',
    GET_DELINQUENCY_REGION_DATA:'v1/delinquency-dashboard/region/',
    GET_DELINQUENCY_BRANCH_DATA:'v1/delinquency-dashboard/branch/',
    GET_DELINQUENCY_FOS_DATA:'v1/delinquency-dashboard/summary/branch/{branchID}',

    //Esign Enable/Disable
    PUT_ESIGN_ENABLE_DISABLE:'v1/loan/document/esign/',

    //Manage Form Permission
    POST_PERMISSION:'v1/permission/'
 };




